.Projects{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  max-width: 120rem;
  min-width: 20rem;
  margin-left: 2rem;
  margin-right: 2rem;
  align-self:flex-start;
  animation: UptoDown 1s ease-in-out, OpacityUp 1s ease-in-out;
}
.ProjectsTypeSection{
  width: 100%;
  height: 100%;
}
.ProjectsTypeSectionHeaderRow{
  display: flex;
  flex-direction: row;
  margin: 0;
  height: 2rem;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}.Whiteline{
  background-color: var(--secondary);
  width: 100%;
  height: .15rem;
}.ProjectsTypeSectionHeaderRowText{
  min-width: 17rem;
}

.FilterRow{
  display: flex;
  flex-direction: row;
}

.FilterItem {
  width: calc(100% / 6);
  height: 10rem;
  border-radius: .7rem;
  overflow: hidden;
  outline: .3rem solid transparent;
  display: flex;
  transition: .25s ease-in-out;
  cursor: pointer;
}.FilterItem:hover {
  outline: .3rem solid var(--primary);
}.FilterItem.Active{
}
.FilterItemContent{
  position: relative;
  display: flex;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  margin: .5rem;
  border-radius: .5rem;
  overflow: hidden;
}

.FilterBackground{
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: var(--tertiary);
  opacity: 0%;
  transition: .25s ease-in-out;
  filter: blur(0.5rem);
}.FilterBackground:hover{
  opacity: 100%;
  filter: blur(0rem);
}.FilterBackground.Active{
  opacity: 100%;
  filter: blur(0rem);
}

.FilterOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  background-color: var(--tertiary);
  transition: .25s ease-in-out;
}.FilterItemContent:hover .FilterOverlay {
  background-color: transparent;
}.FilterOverlay.Active{
  background-color: transparent;
}





.ThumbnailGrid{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.Thumbnail{
  width: calc(100% / 5);
  height: 13rem;
  border-radius: .7rem;
  overflow: hidden;
  outline: .3rem solid transparent;
  display: flex;
  transition: .25s ease-in-out;
  cursor: pointer;
  min-width: 13rem;
}.Thumbnail:hover {
  outline: .3rem solid var(--primary);
}
.ThumbnailItemContent{
  position: relative;
  display: flex;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  margin: .5rem;
  border-radius: .5rem;
  overflow: hidden;
}
.ThumbnailBackground{
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 70%;
  scale: 101%;
  transition: .25s ease-in-out;
}.ThumbnailBackground:hover{
  opacity: 100%;
  scale: 100%;
}


.ThumbnailOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  transition: .25s ease-in-out;
}.ThumbnailItemContent:hover .ThumbnailOverlay {
  background-color: transparent;
}

