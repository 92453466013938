.Legal{
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
  max-width: 60rem;
  min-width: 20rem;
  margin-left: 6rem;
  margin-right: 6rem;
}
.indentone{
  margin-left: 3rem;
}
.indenttwo{
  margin-left: 6rem;
}
.row{
  display: flex;
  flex-direction: row;
  gap: 1rem;
}