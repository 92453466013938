.About{
}

.AboutTextBlock{
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  max-width: 60rem;
  min-width: 20rem;
  padding-left: 2rem;
  padding-right: 2rem;
  gap: .5rem;
  animation: OpacityUp 1s ease-in-out, UptoDown 1s ease-in-out;
}