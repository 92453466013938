.Contact{
  animation: OpacityUp 1s ease-in-out, UptoDown 1s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100vw;
  max-width: 120rem;
  height: 100%;
}

.HorizontalDevider{
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-self: center;
  align-self: center;
  padding-top: 2rem;
  gap: 12rem;
}
.WidthHalfLocation{
  display: flex;
  width: 100%;
  flex-direction: column;

}





.Form{
  animation: LRIn .5s ease-in-out;
}
.FormRow{
  display: flex;
  flex-direction: column;
  gap: .2rem;
  margin-bottom: 1rem;
}
.FormTitleRow{
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
}
.FormTextInput{
  background-color: transparent;
  border-radius: .5rem;
  border: .15rem solid white;
  color: white;
  height: 2.5rem;
  width: 25rem;
  padding-left: .7rem;
  padding-right: .7rem;
  transition: .25s ease;
  font-size: large;
  outline: none;

}
.FormTextInputBig{
  background-color: transparent;
  border-radius: .5rem;
  border: .15rem solid white;
  color: white;
  min-height: 2.5rem;
  width: 25rem;
  padding-left: .7rem;
  padding-right: .7rem;
  transition: .25s ease;
  font-size: large;
  resize: none;
  overflow: hidden;
  font-family: inherit;
  outline: none;
}
.error {
  color: var(--danger);
}