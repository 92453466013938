.CarouselWrapper {
  display: flex;
  width: 100%;
  height: 30rem;
  border-radius: 2rem;
  overflow: hidden;
}

.Carousel {
  display: flex;
  width: 100%;
  height: 30rem;
  justify-content: center;
  align-items: center;
  position: relative;
}

.CarouselCover {
  flex-shrink: 0;
  opacity: 50%;
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: opacity 0.5s ease;
}

.CarouselCover.animateCover {
  animation: CoverIn 1s ease-in-out;
}

.CarouselLogo:hover ~ .CarouselCover {
  opacity: 25%;
}

.ClickableArea {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.CarouselLogo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.CarouselLogo.animateLogo {
  animation: LogoIn 1s ease-out;
}

.carousel-status {
  display: none;
}

@keyframes CoverIn {
  from {
    filter: blur(0.8rem);
    transform: scale(1.01);
  }
  to {
    filter: none;
    transform: scale(1);
  }
}

@keyframes LogoIn {
  from {
    opacity: 0;
    height: 90%;
  }
  to {
    opacity: 100%;
    height: 100%;
  }
}
