.Header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 2rem;
  padding-top: 3rem;
}

.NavigationButtons{
  display: flex;
  flex-direction: row;
  gap: 3rem;
  align-items: start;
}