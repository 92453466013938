.Home{
  
}

.backgroundvideo{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 90%;
  object-fit: cover;
  z-index: -1;
  animation: OpacityUp 2s ease-in-out;
}

.TitleSection{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: OpacityUp 1s ease-in-out, UptoDown 1s ease-in-out;
}