.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
}

.HeaderSection{
  display: flex;
  width: 100vw;
  height: 8rem;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
}

.AppSection{
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.FooterSection{
  display: flex;
  width: 100vw;
  height: 18rem;
  background: linear-gradient(0deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
}