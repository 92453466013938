.Admin{

}

.adminPanel{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 80rem;
  max-width: 120rem;
}
.AdminShowList{
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.AdminShowBlock{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.AdminShowBlockImagesSection{
  display: flex;
  flex-direction: row;
  width: 18rem;
  height: 100%;
  gap: 1rem;
}
.AdminImage{
  display: flex;
  width: 16rem;
  height: 9rem;
  border-radius: .5rem;
}
.AdminShowBlockImagesWithDetails{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--tertiary);
  padding: 1rem;
  border-radius: 1rem;
  gap: 1rem;
}

.AdminShowBlockDetailsSection{
  display: flex;
  flex-direction: row;
  background-color: var(--tertiary);
  border-radius: 1rem;
  gap: 1rem;
}
.AdminFormTextInput{
  background-color: transparent;
  border-radius: .5rem;
  border: .15rem solid white;
  color: white;
  height: 2.5rem;
  width: 15rem;
  padding-left: .5rem;
  padding-right: .5rem;
  transition: .25s ease;
  font-size: large;
  outline: none;

}
.AdminShowBlockDetailsBigInputs{
  display: flex;
  flex-direction: column;
  width: 16rem;
  gap: 1rem;
  padding: 1rem;
}
.AdminFormRow{
  display: flex;
  flex-direction: column;
  width: 16rem;
}
.AdminFormMultipleInputs{
  display: flex;
  width: 16rem;
  flex-direction: column;
  padding: 1rem;
}
.SpecialInputsList{
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  height: 16.4rem;
  width: 16rem;
  overflow: scroll;
  border: .15rem solid var(--primary);
  border-radius: 1rem;
  gap: .5rem;
  
}
.SpecialInputRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}