.ProjectFocus{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 3;
  animation: OpacityUp 0.5s ease-in-out, ScaleDown .5s ease-in-out;
}
.BackgroundImage{
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  animation: fadeInContent 0.1s ease-in-out;
  display: block;
  filter: blur(.55rem);
}
.HorizontalSplit{
  position: fixed;
  display: flex;
  padding: 6rem;
  padding-left: 12rem;
  padding-right: 12rem;
  max-width: 100rem;
  min-width: 90rem;
  top: 0;
  left: 0;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.ArtworkTextSection{
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 2rem;
}
.SoftwareList{
  display: flex;
  flex-direction: column;
}
.HighlightImage{
  width: 40rem;
  height: 40rem;
  object-fit: cover;
  border-radius: 1rem;
  overflow: hidden;
  margin: 2rem;
}
.ColorPaletteRow{
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.HexCodeText{
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 0;
  color: var(--primary);
  width: 8rem;
  text-align: center;
  height: 2.5rem;
  border-radius: 1rem;
  cursor: pointer;
}































.VerticalSplit{
  position: fixed;
  display: flex;
  padding-right: 6rem;
  padding-left: 6rem;
  padding-top: 4rem;
  overflow: scroll;
  top: 0;
  left: 0;
  flex-direction: column;
  width: calc(100vw - 12rem);
  height: 100%;
  gap: 12rem;
  background-size: 100% 100%;
  background-position: 0px 0px,0px 0px;
  background-image: linear-gradient(0deg, #000000FF 0%, #000000FF 19%, #000000BA 44%, #00000000 100%),linear-gradient(90deg, #000000FF 0%, #000000FF 19%, #000000BA 39%, #00000000 100%);
}
.Hsplit{
  display: flex;
  width: 100%;
  height: 100%;
  border-top: .15rem solid var(--secondary);
}
.AdditionalImages{
  width: 50%;
}.ActsList{
  width: 50%;
}.TextDataList{
  display: flex;
  flex-direction: column;
}


.ShowFocusShowLogo{
  width: 25rem;
  object-fit: contain;
  border-radius: 1rem;
  overflow: hidden;
  margin: 2rem;
  transition: .25s ease-in-out;
  cursor: pointer;
}.ShowFocusShowLogo:hover{
  opacity: 75%;
  scale: 105%;
}.ShowFocusShowLogo:active{
  opacity: 25%;
}


.CloseIcon {
  width: 2.5rem;
  height: 2.5rem;
}

.CloseButton {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  color: var(--primary);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  z-index: 5;
  transition: .25s ease-in-out;
}

.CloseButton:hover {
  color: var(--secondary);
}



.ExtraImagesGrid{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;

}
.ExtraImage{
  width: 25rem;
  height: 16rem;
  border-radius: 1rem;
  object-fit: cover;
  cursor: pointer;
}