body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  display: flex;
  justify-content: center;
  font-size: 1.625rem;
  line-height: 1.625rem;
  margin: 0;
  color: var(--primary);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*variables*/
:root {
  --background: #000000;
  --primary: #ECEDEE;
  --secondary: #808080;
  --tertiary: #101010;
  --succes: #30784a;
  --danger: #c93636;
  --dangerlow: #970000;
}

/*MISC*/
.NoSelect{
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

/*layout*/
rowoutside{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: .15rem solid var(--tertiary);
  padding-bottom: 1rem;
}
.small{
  margin: 0;
  width: fit-content;
  height: fit-content;
}
.fitsize{
  width: fit-content;
  height: fit-content;
}


/*Typohraphy*/
h1{
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 900;
  margin: 0rem;
  color: var(--primary);
}

h2{
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 700;
  margin: 0;
  color: var(--primary);
}

h3{
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 700;
  margin-bottom: 0;
  color: var(--primary);
}

.p1{
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 0;
  color: var(--primary);
}

.p2{
  font-size: 1.2rem;
  line-height: 1.625rem;
  margin: 0;
  color: var(--primary);
}
.p3{
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
  color: var(--secondary);
}
.p3w{
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
  color: var(--primary);
}
.p4{
  font-size: 1.2rem;
  line-height: 1.625rem;
  margin: 0;
  color: var(--secondary);
}
.Icon{
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
}

/*Button*/
.BigIconButton{
  color: var(--primary);
  background-color: transparent;
  border: none;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  transition: .25s;
}.BigIconButton:hover{
  opacity: 75%;;
}.BigIconButton:active{
  opacity: 25%;;
}

.b1{
background-color: var(--background);
color: var(--primary);
font-size: 1.5rem;
padding: .5rem;
padding-left: 1rem;
padding-right: 1rem;
border-radius: 1rem;
border: .15rem solid var(--primary);
transition: .25s;
cursor: pointer;
-webkit-touch-callout: none;
-webkit-user-select: none;
user-select: none;
text-decoration: none;
}.b1:hover{
  color: var(--background);
  background-color: var(--primary);
}.b1:active{
  color: var(--background);
  background-color: var(--secondary);
  border-color: var(--secondary);
}


.b2{
  background-color: var(--primary);
  color: var(--background);
  font-size: 1.5rem;
  padding: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 1rem;
  border: .15rem solid var(--primary);
  transition: .25s;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: fit-content;
}
.b2:hover{
  color: var(--primary);
  background-color: var(--background);
}.b2:active{
  color: var(--secondary);
  background-color: var(--background);
  border-color: var(--secondary);
}

.b3{
  background-color: transparent;
  color: var(--primary);
  font-size: 1.5rem;
  border-radius: 1rem;
  border: none;
  transition: .25s;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
}.b3:hover{
  color: var(--secondary);
}.b3:active{
  color: var(--tertiary);
}

.b4{
  background-color: transparent;
  color: var(--secondary);
  font-size: 1.5rem;
  line-height: 2.25rem;
  border-radius: 1rem;
  border: none;
  transition: .25s;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  width: fit-content;
  margin: 0;
}.b4:hover{
  color: var(--primary);
}.b4:active{
  color: var(--secondary);
}


/*Inputs*/
.input1{
  display: flex;
  flex-direction: column;
  gap: .5rem;
}.input1Input{
  background-color: var(--background);
  color: var(--primary);
  padding: 1rem;
  border: .15rem solid var(--primary);
  border-radius: .75rem;
  font-size: 1.625rem;
  line-height: 1.625rem;
  width: 15rem;
  outline: none;
}.input1labelrow{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  align-items: end;
}



/*Animations*/
@keyframes OpacityUp {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
@keyframes OpacityDown {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
@keyframes UptoDown {
  from {
    transform: translateY(-1rem);
  }
  to {
    transform: translateY(0rem);
  }
}
@keyframes ScaleDown {
  from {
    scale: 102%;
  }
  to {
    scale: 100%;

  }
}