.Footer{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 4rem;
}

.FooterDataSection{
  display: flex;
  flex-direction: row;
}
.FooterCollumns{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.FooterLegalRow{
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

